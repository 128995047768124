import { FallbackLoading } from "app/components/util/fallbackLoading";
import React, { Component } from "react";
import ReactDOM from "react-dom";

export class AppLoader extends Component {
  static renderDOM(appElement: HTMLElement = null) {
    ReactDOM.render(<AppLoader />, appElement || document.getElementById("app"));
  }

  render() {
    return <FallbackLoading />;
  }
}

export default AppLoader;
